
import { Options, Vue } from 'vue-class-component';
import { BugManagementClient } from '@/services/Services';
import * as OM from '@/Model';

@Options({
    components: {
    }
})


export default class BugReportDetail extends Vue {

    reportIdentifier: string = "";
    reportDetail: OM.BugReportDetailVM = new OM.BugReportDetailVM();
    
    created() {
        this.reportIdentifier = this.$route.params.identifier.toString();
        BugManagementClient.getReportDetail(this.reportIdentifier)
        .then(x => {
            this.reportDetail = x;
        })
    }

}
